<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="rounded-xl px-4" flat>
          <v-toolbar class="font-weight-bold d-flex justify-center" flat>
            Account Settings
          </v-toolbar>
          <v-form ref="accountForm" class="text-left">
            <label>Full Name</label>
            <v-text-field
              rounded
              dense
              solo
              flat
              background-color="secondary"
              v-model="form.name"
            ></v-text-field>
            <label>Company / Organization</label>
            <v-text-field
              rounded
              dense
              solo
              flat
              background-color="secondary"
              v-model="form['company']"
            ></v-text-field>
            <label>Job Title / Bio</label>
            <v-text-field
              rounded
              dense
              solo
              flat
              background-color="secondary"
              v-model="form['job_title']"
            ></v-text-field>
            <label>Email</label>
            <v-text-field
              rounded
              dense
              solo
              flat
              background-color="secondary"
              v-model="form.email"
            ></v-text-field>
            <v-checkbox
              true-value="yes"
              false-value="no"
              v-model="form['new_recording_notify']"
              color="primary"
              class="my-0 py-0"
            >
              <template v-slot:label>
                <small> Notify me when new recording is received </small>
              </template>
            </v-checkbox>
            <v-checkbox
              false-value="no"
              true-value="yes"
              v-model="form['usage_notification']"
              color="primary"
              class="my-0 py-0 mt-n3"
            >
              <template v-slot:label>
                <small> Send me usage notification emails </small>
              </template>
            </v-checkbox>
          </v-form>
          <v-card-actions class="pb-7">
            <v-spacer></v-spacer>
            <v-btn
              @click="updateUser"
              :loading="loading"
              class="capitalize primary"
              rounded
            >
              save changes
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="rounded-xl px-4" flat>
          <v-toolbar class="font-weight-bold d-flex justify-center" flat>
            Profile Picture
          </v-toolbar>
          <v-form ref="imageForm" class="text-left">
            <v-list-item>
              <v-avatar size="80">
                <v-img :src="user['image'] ? user['image'] : avatar"></v-img>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-file-input
                style="display: none"
                id="selectImage"
                @change="uploadImage()"
                v-model="form.file"
              ></v-file-input>
              <v-btn
                onclick="document.getElementById('selectImage').click()"
                class="primary capitalize"
                rounded
                text
                :loading="loadImage"
              >
                change avatar
              </v-btn>
              <v-spacer></v-spacer>
            </v-list-item>
            <div class="font-weight-bold text-center mt-7 mb-3">Password</div>
            <label>Current Password</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              placeholder="Password"
              :type="!showPassword ? 'password' : 'text'"
              v-model="form['old_password']"
            >
              <template v-slot:append>
                <v-icon
                  @click="showPassword = !showPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
            <label>New Password</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              placeholder="Password"
              :type="!showNewPassword ? 'password' : 'text'"
              v-model="form['password']"
            >
              <template v-slot:append>
                <v-icon
                  @click="showNewPassword = !showNewPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showNewPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
            <label>Confirm New Password</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              placeholder="Password"
              :type="!showConfirmPassword ? 'password' : 'text'"
              v-model="form['password_confirmation']"
            >
              <template v-slot:append>
                <v-icon
                  @click="showConfirmPassword = !showConfirmPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showConfirmPassword
                      ? "mdi-eye-outline"
                      : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
          </v-form>
          <v-card-actions class="pb-7">
            <v-spacer></v-spacer>
            <v-btn
              @click="changePassword"
              :loading="loadPassword"
              class="capitalize primary"
              rounded
            >
              save changes
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="px-7 rounded-xl" flat>
          <v-toolbar class="font-weight-bold d-flex justify-center" flat>
            Add New Account
          </v-toolbar>
          <v-form class="text-left" lazy-validation ref="newAccount">
            <v-text-field
              rounded
              dense
              solo
              flat
              background-color="secondary"
              v-model="newAccount.name"
              placeholder="Enter account name"
            ></v-text-field>
            <v-text-field
              rounded
              dense
              solo
              flat
              background-color="secondary"
              v-model="newAccount.email"
              :rules="[(v) => !!v || 'This email is required']"
              type="email"
              placeholder="Enter account email"
            ></v-text-field>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              placeholder="Password"
              :type="!showAccountPassword ? 'password' : 'text'"
              v-model="newAccount['password']"
              :rules="[(v) => !!v || 'This password is required']"
            >
              <template v-slot:append>
                <v-icon
                  @click="showAccountPassword = !showAccountPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showAccountPassword
                      ? "mdi-eye-outline"
                      : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              placeholder="Free minutes (e.g 20)"
              v-model.number="newAccount['free_minutes']"
            ></v-text-field>
          </v-form>
          <v-card-actions class="pb-7">
            <v-spacer></v-spacer>
            <v-btn
              @click="$refs.newAccount.validate() ? addAccount() : null"
              :loading="loadAccount"
              class="capitalize primary"
              rounded
            >
              Add Account
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
        <v-card flat class="mt-4 pa-2 rounded-xl">
          <small>
            <strong>Enter minutes allocation for Free Account</strong>
          </small>
          <v-form lazy-validation ref="minutes">
            <v-text-field
              dense
              v-model="form.free_minutes"
              class="white"
              rounded
              flat
              solo
              background-color="#eff3ff"
              placeholder="Enter minutes"
              :value="form.free_minutes"
              :rules="[(v) => !!v || 'This field cannot be empty']"
            ></v-text-field>
          </v-form>
          <v-card-actions class="mt-n4">
            <v-spacer></v-spacer>
            <v-btn
              class="primary capitalize px-5"
              rounded
              depressed
              @click="$refs.minutes.validate() ? addMinutes() : null"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Profile",
  data() {
    return {
      avatar: require("../../assets/avatar.png"),
      form: {
        free_minutes: "",
      },
      newAccount: {},
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      showAccountPassword: false,
      loading: false,
      loadImage: false,
      loadPassword: false,
      loadAccount: false,
    };
  },
  created() {
    this.form = Object.assign({}, this.user);
    this.getCompanySetup();
  },
  computed: {
    ...mapGetters("profile", ["user"]),
  },
  methods: {
    ...mapActions("profile", [
      "update_profile",
      "change_password",
      "add_minutes",
      "get_company_setup",
    ]),
    ...mapActions("auth", ["add_account", "LOADING"]),
    async updateUser() {
      this.loading = true;
      try {
        const response = await this.update_profile(this.form);
        this.loading = false;
        this.$response.sendSuccess(response);
      } catch (error) {
        this.loading = false;
        this.$response.sendError(error);
      }
    },
    async uploadImage() {
      this.loadImage = true;
      try {
        let formData = new FormData();
        formData.append("image", this.form.file);
        // noinspection JSCheckFunctionSignatures
        let response = await this.update_profile(formData);
        this.loadImage = false;
        this.$response.sendSuccess(response);
      } catch (e) {
        this.loadImage = false;
        this.$response.sendError(e);
      }
    },
    async changePassword() {
      this.loadPassword = true;
      try {
        const response = await this.change_password(this.form);
        this.loadPassword = false;
        this.$response.sendSuccess(response);
        this.form.old_password = null;
        this.form.password = null;
        this.form.password_confirmation = null;
      } catch (e) {
        this.loadPassword = false;
        this.$response.sendError(e);
      }
    },
    async addAccount() {
      this.loadAccount = true;
      try {
        const response = await this.add_account(this.newAccount);
        this.$response.sendSuccess(response);
        this.loadAccount = false;
        this.$refs.newAccount.reset();
      } catch (e) {
        this.loadAccount = false;
        this.$response.sendError(e);
      }
    },
    async addMinutes() {
      this.LOADING(true);
      try {
        const response = await this.add_minutes(this.form);
        // this.LOADING(false);
        this.$response.sendSuccess(response);
      } catch (e) {
        this.LOADING(false);
        this.$response.sendError(e);
      }
    },
    async getCompanySetup() {
      try {
        const response = await this.get_company_setup();
        this.form.free_minutes = response.data.free_minutes;
        console.log(this.form);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped></style>
